import React, { useRef } from 'react';
import { Container, Text, Title3 } from '../Styled';
import { Divider } from '../common/Divider';
import ImageAboutMob from '../../assets/images/img2-mob.png';
import ImageAbout from '../../assets/images/img2.png';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  AboutImg,
  AboutMobImg,
  LeftSideBox,
  RightSideBox,
  SectionAbout,
  LayoutWrapper,
} from './styled';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { DesktopBreakpoint } from '../../helpers';

export const AboutSection = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  const entry = useIntersectionObserver(ref, {});

  const isVisible = !!entry?.isIntersecting;
  const { width } = useWindowDimensions();
  return (
    <SectionAbout id="about" ref={ref}>
      <Container>
        <LayoutWrapper>
          <RightSideBox>
            <Title3>About CyberActive</Title3>
            {width <= DesktopBreakpoint.value ? (
              <Divider height={20} />
            ) : (
              <Divider height={29} />
            )}
            <Text>
              CyberActive is a Stealth Mode Cybersecurity startup founded in
              2023 with the mission for organizations throughout the globe to be
              immune to cyberattacks with a key focus on business continuity
              utilizing enterprise grade technology at competitive pricing.
              <br />
              <br /> The company was founded by a team of Cybersecurity experts
              bringing over 20 years of experience supporting fortune 100
              companies, the U.S defense and intelligence communities, serving
              in the elite Israeli Military Intelligence Unit 8200, and holding
              multiple Ph.Ds. that lead to CyberActive's autonomous AI
              technology that is revolutionizing security operations.
              <br />
              <br /> Our many years of enterprise security strategy development,
              military cyber-operations experience, innovative software
              technologies, advanced data science, and next-gen threat detection
              modeling algorithms has enabled our organization to deter business
              disruptions at the pace of machine-speed cyberattacks in-progress.
            </Text>
          </RightSideBox>
          <LeftSideBox isVisible={isVisible}>
            {width <= DesktopBreakpoint.value ? (
              <AboutMobImg src={ImageAboutMob} alt="picture" />
            ) : (
              <AboutImg src={ImageAbout} alt="picture" />
            )}
          </LeftSideBox>
        </LayoutWrapper>
      </Container>
    </SectionAbout>
  );
};
