import React from 'react';
import { Container, Section, Text, Title2, Title3 } from '../Styled';
import { Divider } from '../common/Divider';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { BlockContainer } from './styled';
import { Card } from './Card';
import { listOne, listThree, listTwo } from './data';
import { DesktopBreakpoint } from '../../helpers';

export const ImmunitySection: React.FC = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <Section>
        <Container>
          <Title2>Immunity to Cyberattacks</Title2>
          {width <= DesktopBreakpoint.value ? (
            <Divider height={14} />
          ) : (
            <Divider height={20} />
          )}
          <Title3>
            Stop attacks in milliseconds without disrupting business operations
          </Title3>
          {width <= DesktopBreakpoint.value ? (
            <Divider height={20} />
          ) : (
            <Divider height={30} />
          )}
          <Text>
            In todays distributed workforce and hybrid application ecosystem,
            CyberActive prevents cyberattacks across the complete attack surface
            in milliseconds. Our Autonomous AI technology delivers advanced
            real-time high performance threat deterrence models that respond to
            sophisticated &amp; dynamic cybercriminal tactics at the pace of
            evolving AI-enabled cyberattacks.
          </Text>
          <Divider height={20} />
          <BlockContainer>
            <Card title="Extended Attack Surface" items={listOne} />
            <Card title="Advanced Persistent Threats" items={listTwo} />
            <Card title="CyberActive SecOps Platform" items={listThree} />
          </BlockContainer>
        </Container>
      </Section>
    </>
  );
};
