import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { FLEX, FONT } from '../../utils';
import { ReactComponent as LogoFooter } from '../../assets/icons/logo-footer.svg';
import { ReactComponent as IconVector } from '../../assets/icons/vector.svg';
import { COLORS } from '../../assets';
import { DesktopBreakpoint } from '../../helpers';

export const SectionFooter = styled.section`
  padding-top: 40px;
  padding-bottom: 20px;
  background: #0c0221;
  backdrop-filter: blur(40px);
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    padding-top: 20px;
    padding-botton: 14px;
  }
`;
export const FooterTopBox = styled.div`
  ${FLEX({ direction: 'column' })};
  overflow-x: hidden;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ justify: 'space-between' })};
  }
`;
export const StyledIconList = styled.ul`
  ${FLEX({ justify: 'center' })};
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ justify: 'space-between' })};
    margin-left: 148px;
  }
`;

export const LinkItem = styled.li`
  &:not(:last-child) {
    margin-right: 20px;
  }
  ${FLEX({ justify: 'center' })};
`;

export const LinkIcon = styled(Link)`
  display: inline-flex;
  padding: 10px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: #271f37;
  border: none;
  border-radius: 50%;
  text-align: center;
  transition: 0.5s linear;
  &:hover,
  &:focus {
    background: #0a66c2;
  }
`;
export const ImageLogo = styled(LogoFooter)`
  width: 196px;
  height: 49px;
  fill: ${COLORS.white};
  transition: 0.3s linear;
  &:hover,
  &:focus {
    fill: ${COLORS.blue};
  }
`;
export const StyledMenuList = styled.ul`
  ${FLEX({ direction: 'column' })};
  list-style: none;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({})};
  }
`;
export const StyledNavigationListItem = styled.li`
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    margin-bottom: 30px;
    ${FONT({ weight: '400', size: '16px' })};
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    &:not(:last-child) {
      margin-right: 38px;
    }
  }
`;
export const StyledHashLinkFooter = styled(Link)`
  ${FONT({ weight: '400', size: '16px' })};
  line-height: 1.19;
  color: ${COLORS.white};
  transition: 0.5s linear all;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${COLORS.blue};
  }
  &:active {
    color: ${COLORS.blue};
  }
  text-decoration: none;

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    &:not(:last-child) {
      margin-right: 38px;
    }
  }
`;
export const ListWraper = styled.div`
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ direction: 'row-reverse', justify: 'space-between' })};
  }
`;
export const StyledAssign = styled.span`
  ${FONT({ weight: '600', size: '16px' })};
  line-height: 1.19;
  margin-right: 6px;
  color: ${COLORS.white};

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    line-height: 1.21;
  }
`;
export const StyledIconVector = styled(IconVector)`
  width: 14px;
  height: 14px;
  fill: ${COLORS.white};
  transition: 0.3s linea all;
`;
export const StyledFooterText = styled.p`
  ${FONT({})};
  line-height: 1.21;
  color: ${COLORS.gray};
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    margin-top: 30px;
    margin-bottom: 14px;
    text-align: center;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ size: '16px' })};
    line-height: 1.36;
    margin-right: 14px;
  }
`;
export const SignWraper = styled.div`
  ${FLEX({})};
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    margin-bottom: 30px;
  }
`;
export const FooterMidleBox = styled.div`
  ${FLEX({ direction: 'column', justify: 'center', align: 'center' })};
  border-bottom: 1px solid #231a36;
  padding-bottom: 20px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ justify: 'flex-start' })};
    padding-bottom: 30px;
  }
`;
export const LinkAssign = styled(NavLink)`
  display: inline-flex;
  ${FLEX({})};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    margin-right: 214px;
  }
`;
export const StyledNavigationPageList = styled.ul`
  ${FLEX({})};
`;
export const StyledNavigationPageItem = styled.li`
  &:not(:last-child) {
    margin-right: 20px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;
export const CopyWriteWraper = styled.span`
  ${FONT({})};
  line-height: 1.21;
  color: ${COLORS.gray};
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    margin-bottom: 14px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    text-aligh: center;
  }
`;
export const LinkPage = styled(NavLink)`
  ${FONT({})};
  line-height: 1.21;
  color: ${COLORS.white};
  transition: 0.3s linear all;
  &:active {
    color: ${COLORS.blue};
  }
  &:hover,
  &:focus {
    color: ${COLORS.blue};
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    color: ${COLORS.gray};
  }
`;
export const FooterBottomBox = styled.div`
  ${FLEX({ direction: 'column' })};
  padding-bottom: 20px;
  padding-top: 20px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    padding-bottom: 14px;
    padding-top: 20px;
    ${FLEX({ justify: 'space-between' })};
  }
`;
