import { ReactComponent as LogoIcon } from '../../assets/icons/logo1.svg';
import styled from 'styled-components';
import { FLEX } from '../../utils';
import { Link } from 'react-router-dom';
import { COLORS } from '../../assets';
import { DesktopBreakpoint } from '../../helpers';

export const StyledLogoIcon = styled(LogoIcon)`
  width: 278px;
  height: 68px;
`;

export const SuccessWraperBox = styled.div`
  ${FLEX({ direction: 'column', justify: 'space-between', align: 'center' })};
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    padding-top: 111px;
    padding-bottom: 100px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    padding-top: 191px;
    padding-bottom: 122px;
  }
`;
export const LinkInvestor = styled(Link)`
  display: inline-flex;
  align-items: center;
  ${FLEX({})}
  width: 208px;
  height: 64px;
  color: ${COLORS.white};
  border: 2px solid ${COLORS.gray};
  border-radius: 6px;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    transform: scale(1.1);
    border-color: ${COLORS.blue};
  }
`;
