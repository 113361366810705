import React from 'react';
import { Container } from '../Styled';
import { Button } from '../common/Button';
import {
  CyberImg,
  CyberImgMob,
  HeroBox,
  HeroDescription,
  HeroLeftSide,
  HeroPictureBox,
  HeroRightSide,
  SectionHero,
  TitleHero1,
  TitleHero2,
} from './styled';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import ImageHeroMob from '../../assets/images/cyber-mob.png';
import ImageHero from '../../assets/images/cyber.png';
import { DesktopBreakpoint } from '../../helpers';

type THero = {
  contactRef: any;
};

export const Hero: React.FC<THero> = ({ contactRef }) => {
  const size = useWindowDimensions().width;

  return (
    <>
      <SectionHero>
        <Container>
          <HeroBox>
            <HeroRightSide>
              <HeroPictureBox>
                {size <= DesktopBreakpoint.value ? (
                  <CyberImgMob src={ImageHeroMob} />
                ) : (
                  <CyberImg src={ImageHero} />
                )}
              </HeroPictureBox>
            </HeroRightSide>
            <HeroLeftSide>
              <TitleHero1>Autonomous</TitleHero1>
              <TitleHero1>Detection and Response</TitleHero1>
              <TitleHero2>for the Modern Workplace</TitleHero2>
              <HeroDescription>
                CyberActive is radically transforming cybersecurity operations
                for the modern workplace. Our patent-pending Autonomous
                Detection and Response platform is designed to outpace,
                outsmart, and deter sophisticated machine-speed AI-Enabled
                cyberattacks.
                <br />
                <br />
                Join our launch team for early access.
              </HeroDescription>
              <Button
                name="secondary"
                title="Contact us to learn more"
                width={size <= DesktopBreakpoint.value ? '343px' : '330px'}
                height={size <= DesktopBreakpoint.value ? '46px' : '46px'}
                onClick={() => contactRef.current.scrollIntoView()}
              />
            </HeroLeftSide>
          </HeroBox>
        </Container>
      </SectionHero>
    </>
  );
};
