import React from 'react';
import {
  StyledNavigationListItem,
  StyledMenuList,
  StyledHashLinkFooter,
} from './styled';
import { TLinksProps } from '../Header/types';

export const MenuListFooter: React.FC<TLinksProps> = ({ links }) => {
  return (
    <StyledMenuList>
      {links.map(link => (
        <StyledNavigationListItem key={link.id}>
          <StyledHashLinkFooter
            to={link.href}
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {link.title}
          </StyledHashLinkFooter>
        </StyledNavigationListItem>
      ))}
    </StyledMenuList>
  );
};
