import { Breakpoint, TMediaBreakpoints, TMediaQueries } from './types';

export const MediaQueries: TMediaQueries = {
  xs: '<376px',
  md: '>=376px',
};

export const MediaBreakpoints: TMediaBreakpoints = {
  md: 376,
};

export const DesktopBreakpoint: Breakpoint = new Breakpoint(1296);