import React, { useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { CookiesModal } from './components/CookiesModal';
import { AppRouter } from './routes';
import GlobalStyles from './styles/global';
import { initEmail } from './helpers/sendEmail';

const App: React.FC = () => {
  const [active, setActive] = useState(true);
  const [close, setClose] = useState(false);
  const [cookies, setCookies] = useState(() => {
    return document.cookie || '';
  });

  initEmail();

  const handleAcceptCookies = () => {
    document.cookie = 'accept';
    setCookies(cookies);
    setClose(!close);
    setActive(!active);
  };

  const handleDeclineCookies = () => {
    setClose(!close);
    setActive(!active);
  };

  return (
    <>
      <HashRouter basename={process.env.PUBLIC_URL}>
        <GlobalStyles />
        <AppRouter />
        {cookies !== 'accept' && (
          <CookiesModal
            active={active}
            handleAcceptCookies={handleAcceptCookies}
            handleDeclineCookies={handleDeclineCookies}
          />
        )}
      </HashRouter>
    </>
  );
};

export default App;
