import styled from 'styled-components';
import { COLORS } from '../../assets';
import { DesktopBreakpoint } from '../../helpers';
import { FONT } from '../../utils';

export const BlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    gap: 14px;
  }

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    justify-content: space-between;
  }
`;
export const StyledCard = styled.div`
  position: relative;
  padding: 20px;
  background: rgba(30, 17, 56, 0.4);
  backdrop-filter: blur(6px);
  border-radius: 12px;
  transition: 0.5s linear all;
  overflow: hidden;
  &:hover,
  &:focus {
    box-shadow: 5px 5px 5px 5px #5b3ad2;
  }
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    max-width: 343px;
    width: 100%;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: 406px;
    min-height: 366px;
    padding: 20px;
    background: rgba(25, 13, 51, 0.3);
  }
`;
export const TitleCard = styled.h3`
  ${FONT({ size: '16px' })};
  line-height: 1.36;
  margin-bottom: 12px;
  color: ${COLORS.white};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '600', size: '18px' })};
  }
`;
export const CardMenu = styled.ul`
  display: block;
  color: ${COLORS.white};
  padding-left: 20px;
  list-style-type: disc;
  list-style-position: outside;
  overflow: hidden;
  &::before {
    display: block;
    content: ' ';
    width: 154px;
    height: 154px;
    border-radius: 50%;
    position: absolute;
    background: #472fb5;
    opacity: 0.7;
    filter: blur(160px);
    top: -20%;
    left: -20%;
  }
  &::after {
    display: block;
    content: ' ';
    width: 154px;
    height: 154px;
    border-radius: 50%;
    position: absolute;
    background: #472fb5;
    opacity: 0.7;
    filter: blur(160px);
    bottom: -20%;
    right: -20%;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    &::before {
      width: 234px;
      height: 234px;
      border-radius: 50%;
      position: absolute;
      background: #472fb5;
      opacity: 0.7;
      filter: blur(160px);
      top: -30%;
      left: -30%;
    }
    &::after {
      width: 234px;
      height: 234px;
      border-radius: 50%;
      position: absolute;
      background: #472fb5;
      opacity: 0.7;
      filter: blur(160px);
      bottom: -30%;
      right: -30%;
    }
  }
`;
export const CardItem = styled.li`
  ${FONT({ size: '16px' })};
  line-height: 1.21;
  color: ${COLORS.gray};
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ size: '18px' })};
  }
`;
