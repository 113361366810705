import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LinkIcon, LinkItem, StyledIconList } from './styled';
import { TLinksProps } from './types';

export const SocialListLink: React.FC<TLinksProps> = ({ items }) => {
  return (
    <>
      <StyledIconList>
        {items.map(({ Icon, ...item }) => (
          <LinkItem key={uuidv4()}>
            <LinkIcon
              to={item.href}
              title={item.title}
              target="_blank"
              rel="noopener"
              onClick={() => window.open(`${item.href}`)}
            >
              <Icon />
            </LinkIcon>
          </LinkItem>
        ))}
      </StyledIconList>
    </>
  );
};
