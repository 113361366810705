import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { COLORS } from '../../assets';
import { FLEX, FONT } from '../../utils';
import { DesktopBreakpoint } from '../../helpers';

export const Container = styled.div`
  width: 100%;
  padding: 0 16px;
  @media screen and (min-width: 375px) {
    width: 375px;
    margin: 0 auto;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: ${DesktopBreakpoint.px};
    margin: 0 auto;
  }
`;

export const Section = styled.section`
  padding-top: 40px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    padding-top: 120px;
  }
`;
export const LinkTo = styled(NavLink)`
  color: ${COLORS.blue};
  &:active {
    color: ${COLORS.white};
  }
`;

export const Title1 = styled.h1`
  ${FONT({ weight: '700', size: '24px' })};
  text-align: 'right';
  line-height: 1.21;
  margin-bottom: 20px;
  color: ${COLORS.white};

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '700', size: '42px' })};
    line-height: 1.14;
    margin-bottom: 30px;
  }
`;
export const Title2 = styled.h2`
  ${FONT({ weight: '700', size: '20px' })};
  line-height: 1.21;
  color: ${COLORS.white};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '700', size: '36px' })};
    text-align: center;
  }
`;
export const Title = styled.h2`
  ${FONT({ weight: '700', size: '34px' })};
  line-height: 1.21;
  text-align: center;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '700', size: '62px' })};
  }
`;
export const Title3 = styled.h3`
  ${FONT({ weight: '600', size: '17px' })};
  line-height: 1.21;
  color: ${COLORS.white};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '600', size: '24px' })};
    text-align: center;
  }
`;

export const Title4 = styled.h4`
  ${FONT({ weight: '600', size: '16px' })};
  line-height: 1.21;
  color: ${COLORS.white};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '600', size: '18px' })};
  }
`;
export const Text = styled.p`
  ${FONT({ size: '16px' })};
  line-height: 1.21;
  color: ${COLORS.gray};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ size: '18px' })};
    line-height: 1.36;
  }
`;

export const LayoutWrapper = styled.div`
  ${FLEX({ direction: 'column' })};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ direction: 'row', justify: 'space-between' })};
  }
`;
export const TopWrapper = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })};
`;
export const ContainerWrapper = styled.div`
  ${FLEX({ justify: 'space-between' })};
`;
export const CenterWraper = styled.div`
  ${FLEX({})};
`;
export const TitleList = styled(Title3)`
  text-align: start;
`;
export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  /* .toast is passed to toastClassName */
  .toast {
    background-color: #1e1138;
  }
  /* .body is passed to bodyClassName */
  .body {
    color: ${COLORS.white};
  }
  .Toastify__close-button > svg {
    fill: ${COLORS.white};
  }
  .Toastify__toast-icon > svg {
    fill: ${COLORS.blue};
  }
  /* .progress is passed to progressClassName */
  .progress {
    background-color: ${COLORS.blue};
  }
`;
