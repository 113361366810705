import { ReactComponent as Eye } from '../../assets/icons/eye-line.svg';
import { ReactComponent as Bubble } from '../../assets/icons/bubble.svg';
import { ReactComponent as Network } from '../../assets/icons/network.svg';
import { ReactComponent as Database } from '../../assets/icons/database.svg';
import { ReactComponent as Dna } from '../../assets/icons/dna.svg';
import { ReactComponent as Stack } from '../../assets/icons/dna.svg';
export const items = [
  {
    Icon: Eye,
    title: 'Complete Visibility',
    text: 'Live Investigation and Attack Story Analytics',
  },
  {
    Icon: Bubble,
    title: 'Seamless Integrated Ecosystem',
    text: ' Turnkey SaaS Solution & Open Cross-Stack Integration Ecosystem',
  },

  {
    Icon: Network,
    title: 'Self-Learning Autonomous AI',
    text: ' Complete SecOps Automation: Fighting AI with AI',
  },
  {
    Icon: Dna,
    title: 'Zero Day threat Prevention',
    text: ' Advanced Network Security and Software-DNA-Mapping based Malware analysis',
  },
  {
    Icon: Database,
    title: 'Optimal Performance',
    text: ' AI-driven MTTD & MTTR',
  },
  {
    Icon: Stack,
    title: 'Enterprise Grade',
    text: ' Best in class scalable, robust & feature rich autonomous cybersecurity platform',
  },
];
