import React from 'react';
import { Container, Section, Title1 } from '../Styled';
export const StatementSection: React.FC = () => {
  return (
    <Section>
      <Container>
        <Title1>
          At CyberActive, our mission is to predict and deter business
          disruption risks with the goal for all organizations to be immune to
          cyberthreats.
        </Title1>
      </Container>
    </Section>
  );
};
