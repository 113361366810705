import React from 'react';
import { Text, Title2, Container } from '../../components/Styled';
import { PageSectionWraper } from '../PrivacyPage/styled';
import { items } from './data';
import { ListPurposes } from '../PrivacyPage/ListPurposes';
import { Divider } from '../../components/common/Divider';
import { TitleList } from '../../components/Styled/styled';
import { Footer } from '../../components/Footer';

export const TermsPage: React.FC = () => {
  return (
    <>
      <PageSectionWraper>
        <Container>
          <Title2>CYBERACTIVE TERMS OF SERVICE</Title2>
          <Divider height={20} />
          <TitleList>1. Terms</TitleList>
          <Divider height={14} />
          <Text>
            By accessing the CyberActive website (the “Site”), you are agreeing
            to be bound by these web site Terms and Conditions of Use, all
            applicable laws and regulations, and agree that you are responsible
            for compliance with any applicable local laws. If you do not agree
            with any of these terms, you are prohibited from using or accessing
            this site. The materials contained in this web site are protected by
            applicable copyright and trade mark law.
            <br />
            <br />
          </Text>

          <TitleList>2. Use License</TitleList>
          <Divider height={14} />
          <Text>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on CyberActive’s web site for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not:
          </Text>
          <Divider height={10} />
          <ListPurposes items={items} />
          <Divider height={10} />
          <Text>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by CyberActive at any time.
            Upon terminating your viewing of these materials or upon the
            termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed
            format.
            <br />
            <br />
          </Text>
          <TitleList>3. Disclaimer</TitleList>
          <Divider height={10} />
          <Text>
            The materials on CyberActive’s web site are provided “as is”.
            CyberActive makes no warranties, expressed or implied, and hereby
            disclaims and negates all other warranties, including without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights. Further,
            CyberActive does not warrant or make any representations concerning
            the accuracy, likely results, or reliability of the use of the
            materials on its Internet web site or otherwise relating to such
            materials or on any sites linked to this site.
            <br />
            <br />
          </Text>
          <TitleList>4. Limitations of Liability</TitleList>
          <Divider height={10} />
          <Text>
            In no event shall CyberActive, its business partners, or its
            suppliers be liable for any damages (including, without limitation,
            damages for loss of data or profit, or due to business
            interruption,) arising out of the use or inability to use the
            materials on CyberActive’s Internet site, even if CyberActive or an
            CyberActive authorized representative has been notified orally or in
            writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
            <br />
            <br />
          </Text>
          <TitleList>5. Revisions and Errata</TitleList>
          <Divider height={10} />
          <Text>
            The materials appearing on CyberActive’s web site could include
            technical, typographical, or photographic errors. CyberActive does
            not warrant that any of the materials on its web site are accurate,
            complete, or current. CyberActive may make changes to the materials
            contained on its web site at any time without notice. CyberActive
            does not, however, make any commitment to update the materials.
            <br />
            <br />
          </Text>
          <TitleList>6. Links</TitleList>
          <Divider height={10} />
          <Text>
            CyberActive has not reviewed all the sites linked to its Internet
            web site and is not responsible for the contents of any such linked
            site. The inclusion of any link does not imply endorsement by
            CyberActive of the site. Use of any such linked web site is at the
            user’s own risk.
            <br />
            <br />
          </Text>
          <TitleList>7. Site Terms of Use Modifications</TitleList>
          <Divider height={10} />
          <Text>
            CyberActive may revise these terms of use for its web site at any
            time without notice. By using this website you are agreeing to be
            bound by the then current version of these Terms and Conditions of
            Use.
            <br />
            <br />
          </Text>
          <TitleList>8. Governing Law</TitleList>
          <Divider height={10} />
          <Text>
            Any claim relating to CyberActive’s web site shall be governed by
            the laws of the State of Delaware without regard to its conflict of
            law provisions.
          </Text>
        </Container>
      </PageSectionWraper>
      <Footer />
    </>
  );
};
