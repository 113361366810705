import React, { useRef } from 'react';
import { Hero } from '../../components/Hero';
import { ImmunitySection } from '../../components/ImmunitySection';
import { TechnologySection } from '../../components/TechnologySection';
import { WhyCyberSection } from '../../components/WhyCyberSection';
import { AboutSection } from '../../components/AboutSection/index';
import { ContactForm } from '../../components/ContactForm';
import { Footer } from '../../components/Footer/Footer';
import { StatementSection } from '../../components/StatementSection/StatementSection';
import ButtonScrollBack from '../../components/common/Button/ButtonScrollBack';

export const HomePage: React.FC = () => {
  const contactFormRef = useRef();

  return (
    <>
      <Hero contactRef={contactFormRef} />
      <ImmunitySection />
      <WhyCyberSection />
      <TechnologySection />
      <StatementSection />
      <AboutSection />
      <ContactForm blockRef={contactFormRef} />
      <ButtonScrollBack />
      <Footer />
    </>
  );
};
