import React from 'react';
import { Divider } from '../common/Divider';
import { LinkTo } from '../Styled';
import { ModalButton } from '../Modal/styled';
import { ReactComponent as CloseButton } from '../../assets/icons/close-modal.svg';
import {
  StyledCookiesModal,
  StyledButtonWraper,
  ButtonCookies,
  TextCookies,
} from './styled';
import { TCookiesModalProps } from './types';

export const CookiesModal: React.FC<TCookiesModalProps> = ({
  active,
  handleDeclineCookies,
  handleAcceptCookies,
}) => {
  return (
    <StyledCookiesModal className={active ? 'active' : ''}>
      <ModalButton onClick={handleDeclineCookies}>
        <CloseButton />
      </ModalButton>
      <TextCookies>
          This site uses cookies essential to its operation, for analytics, and
        for personalized content and ads. By continuing to browse this site, you
        acknowledge the use of cookies.
      </TextCookies>
      <Divider height={20} />
      <LinkTo
        to="/privacy"
        target="_blank"
        aria-label="link page"
        title="Go to Privacy Policy"
      >
        Privacy statement
      </LinkTo>

      <Divider height={14} />
      <StyledButtonWraper>
        <ButtonCookies
          name="accept"
          type="button"
          onClick={handleAcceptCookies}
        >
          Accept
        </ButtonCookies>
        <ButtonCookies
          name="decline"
          type="button"
          onClick={handleDeclineCookies}
        >
          Decline
        </ButtonCookies>
      </StyledButtonWraper>
    </StyledCookiesModal>
  );
};
