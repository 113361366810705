import React from 'react';
import { StyledHashLink, StyledMenuList, NavigationListItem } from './styled';
import { TLinksProps } from './types';

export const MenuList: React.FC<TLinksProps> = ({ links, handleLink }) => {
  return (
    <StyledMenuList>
      {links.map(link => (
        <NavigationListItem key={link.id}>
          <StyledHashLink
            to={link.href}
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={handleLink}
          >
            {link.title}
          </StyledHashLink>
        </NavigationListItem>
      ))}
    </StyledMenuList>
  );
};
