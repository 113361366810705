import * as Yup from 'yup';

export const ContactSchema = Yup.object({
  name: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  email: Yup.string().email('Incorrect email address').required('Required'),
  agreeContact: Yup.boolean().oneOf([true], 'You must accept this condition'),
  agreeMail: Yup.boolean(),
  agreePersonalData: Yup.boolean().oneOf(
    [true],
    'You must accept this condition',
  ),
});
