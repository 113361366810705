import { Field } from 'formik';
import styled from 'styled-components';
import checkIcon from '../../../assets/bg/check.png';
import { Error } from '../Input/styled';

export const CheckBoxWraper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 20px;
`;

export const LabelCheckbox = styled.label`
  position: relative;
  padding: 0 0 0 44px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #12062c;
`;

export const StyledCheckbox = styled(Field)`
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 20px;
  height: 20px;

  & + ${LabelCheckbox}:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #12062c;
    border-radius: 2px;
    transition: 0.2s;
  }
  &:checked + ${LabelCheckbox}:before {
    background: #12062c;
    border-color: transparent;
    background-image: url('${checkIcon}');
    background-repeat: no-repeat;
    background-position: center center;
  }
`;
export const ErrorCheck = styled(Error)`
  top: 35px;
  left: 42px;
`;
