import React, { useEffect, useState } from 'react';
import { ReactComponent as ScrollIcon } from '../../../assets/icons/scroll.svg';
import { StyledButtonScrollBack } from './styled';

const ButtonScrollBack: React.FC = () => {
  const [isButtonVisiable, setIsButtonVisiable] = useState<boolean>(false);

  const Events = {
    clickButtonHandler: () => {
      window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    },
    scrollHandler: () => {
      const scrollPosition = window.scrollY;
      const maxLimit = 600;
      setIsButtonVisiable(scrollPosition > maxLimit);
    },
  };

  useEffect(() => {
    window.addEventListener('scroll', Events.scrollHandler);
  }, [Events.scrollHandler]);

  return (
    <>
      {isButtonVisiable && (
        <StyledButtonScrollBack onClick={Events.clickButtonHandler}>
          <ScrollIcon />
        </StyledButtonScrollBack>
      )}
    </>
  );
};

export default ButtonScrollBack;
