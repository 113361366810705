import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TLinksPagesProps } from './types';
import {
  LinkPage,
  StyledNavigationPageList,
  StyledNavigationPageItem,
} from './styled';

export const NavigationLinksPages: React.FC<TLinksPagesProps> = ({
  items,
  handleClick,
}) => {
  return (
    <>
      <StyledNavigationPageList>
        {items.map(({ href, title }) => (
          <StyledNavigationPageItem key={uuidv4()}>
            <LinkPage to={href} target="_blank" rel="noopener noreferrer">
              {title}
            </LinkPage>
          </StyledNavigationPageItem>
        ))}
      </StyledNavigationPageList>
    </>
  );
};
