import styled from 'styled-components';
import { FLEX } from '../../utils';
import BgImageMob from '../../assets/bg/bg-mob-img.png';
import BgImage from '../../assets/bg/bg-img.png';
import { DesktopBreakpoint } from '../../helpers';

export const LeftSideBar = styled.div<{
  isVisible: boolean;
}>`
  ${FLEX({})};
  flex-shrink: 0;
  width: 343px;
  height: 290px;
  text-align: center;
  background-image: url('${BgImage}');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  animation: ${props => props.isVisible && 'scale 3s 1'};

  @keyframes scale {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    margin-bottom: 14px;
  }

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: 636px;
    height: 539px;
    background-image: url('${BgImageMob}');
  }
`;
export const RightSideBar = styled.div`
  ${FLEX({ direction: 'column', justify: 'flex-start' })};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({
      direction: 'column',
      justify: 'space-between',
      align: 'flex-start',
    })};
    margin-left: 24px;
  }
`;
