import React from 'react';
import { Divider } from '../../components/common/Divider';
import { Container, LinkTo, Text, Title3 } from '../../components/Styled';
import { PageSectionWraper } from './styled';
import { items } from './data';
import { ListPurposes } from './ListPurposes';
import { Footer } from '../../components/Footer';

export const PrivacyPage: React.FC = () => {
  return (
    <>
      <PageSectionWraper>
        <Container>
          <Title3>CYBERACTIVE PRIVACY POLICY</Title3>
          <Divider height={14} />
          <Text>
            <br />
            CyberActive along with its subsidiaries, business partners, and
            affiliates worldwide (collectively “CyberActive”, “we” or “us”),
            respects your privacy. This Privacy Policy governs the way
            CyberActive collects, uses, maintains and discloses information
            collected from users (each, a “User”) of the 
            <LinkTo
              to="/"
              aria-label="link site"
              title="Go to site"
              target="_blanked"
              href="https://cyberactive.com/#"
            >
              www.cyberactive.com
            </LinkTo>
             website (“Site” or “websites”). This privacy policy applies to the
            Site and all products and services offered by CyberActive.
            <br />
            <br /> Personal Identification Information
            <br /> We may collect personal identification information from Users
            in a variety of ways, including, but not limited to, when Users
            visit our site, register on the site, subscribe to the newsletter,
            fill out a form, and in connection with other activities, services,
            features or resources we make available on our Site. Users may be
            asked for, as appropriate, name, email address, phone number. Users
            may, however, visit our Site anonymously. We will collect personal
            identification information from Users only if they voluntarily
            submit such information to us. Users can always refuse to supply
            personally identification information, except that it may prevent
            them from engaging in certain Site related activities.
            <br /> <br />
            Non-personal Identification Information <br />
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized and other similar information.
            <br />
            <br /> Web Browser Cookies
            <br /> Our Site may use “cookies” to enhance User experience. User’s
            web browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. User may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
            <br />
            <br /> How We Use Collected Information
            <br /> CyberActive may collect and use Users personal information
            for the following purposes:
            <br />
          </Text>
          <ListPurposes items={items} />

          <Text>
            – To send periodic emails: We may use the email address to send User
            information and updates pertaining to their order. It may also be
            used to respond to their inquiries, questions, and/or other
            requests. If User decides to opt-in to our mailing list, they will
            receive emails that may include company news, updates, related
            product or service information, etc. If at any time the User would
            like to unsubscribe from receiving future emails, we include
            detailed unsubscribe instructions at the bottom of each email.
            <br />
            <br />
            CyberActive’s Commitment to Security & Protecting Your Information
            <br />
            CyberActive is committed to protecting all Personal Information and
            Content we collect and use. We adopt appropriate data collection,
            storage and processing practices and security measures to protect
            against unauthorized access, alteration, disclosure or destruction
            of your personal information, username, password, transaction
            information and data stored on our Site.
            <br />
            <br />
            External Links and Third Party Websites
            <br /> CyberActive’s Websites may provide Uniform Resource Locator
            (URLs) / links to and be accessed via links from third-party
            websites, including social media sites and business partner
            websites, whose privacy policies differ from those of CyberActive.
            CyberActive is not responsible for the content, privacy policies, or
            practices of those third-party websites.
            <br />
            <br />
            Sharing Your Personal Information
            <br /> We do not sell, trade, or rent Users personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding visitors and users with our business partners, trusted
            affiliates and advertisers for the purposes outlined above. We may
            use third party service providers to help us operate our business
            and the Site or administer activities on our behalf, such as sending
            out newsletters or surveys. We may share your information with these
            third parties for those limited purposes provided that you have
            given us your permission.
            <br />
            <br />
            Children
            <br /> Our Websites, Products, and services are not designed or
            intended for minors and we do not knowingly attempt to solicit or
            receive any information from children. However, if a child under 13
            has CyberActive with Personal Information, we advise the parent or
            guardian to contact CyberActive immediately at 
            <LinkTo
              to="#"
              onClick={e => {
                e.preventDefault();
                window.location.href = 'mailto:info@cyberactive.com';
              }}
            >
              info@cyberactive.com
            </LinkTo>
             so that we can delete such information from our records.
            <br />
            <br />
            Changes to This Privacy Policy
            <br /> CyberActive has the discretion to update this privacy policy
            at any time. When we do, we will revise the updated date at the
            bottom of this page. We encourage Users to frequently check this
            page for any changes to stay informed about how we are helping to
            protect the personal information we collect. You acknowledge and
            agree that it is your responsibility to review this privacy policy
            periodically and become aware of modifications. <br />
            <br />
            Your Acceptance of These Terms
            <br /> By using this Site, you signify your acceptance of this
            policy and Terms of Service. If you do not agree to this policy,
            please do not use our Site. Your continued use of the Site following
            the posting of changes to this policy will be deemed your acceptance
            of those changes.
            <br />
            <br />
            Contacting us
            <br /> If you have any questions about this Privacy Policy, the
            practices of this site, or your dealings with this site, please
            contact us at: 
            <LinkTo
              to="#"
              onClick={e => {
                e.preventDefault();
                window.location.href = 'mailto:info@cyberactive.com';
              }}
            >
              info@cyberactive.com
            </LinkTo>
             
          </Text>
        </Container>
      </PageSectionWraper>
      <Footer />
    </>
  );
};

