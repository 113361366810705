import styled from 'styled-components';
import { COLORS } from '../../assets';
import { DesktopBreakpoint } from '../../helpers';
import { FLEX, FONT } from '../../utils';
import { Text } from '../Styled';
export const StyledCookiesModal = styled.div<{ className: string }>`
  display: ${props => (props.className === 'active' ? 'flex' : 'none')};
  width: 100%;
  max-width: 343px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 100%;
  padding: 40px 20px 20px 20px;
  background: #1e1138;
  z-index: 4;
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    left: 0;
  }
`;
export const StyledButtonWraper = styled.div`
  ${FLEX({})};
  gap: 20px;
  margin-top: 20px;
`;
export const ButtonCookies = styled.button<{ name: string }>`
  width: 80px;
  height: 36px;
  padding: 10px;
  ${FONT({})}
  line-height: 1.2;
  color: ${COLORS.white};
  border-radius: 2px;
  border: ${props =>
    props.name === 'accept' ? '2px solid transparent' : 'none'};
  background: ${props =>
    props.name === 'accept'
      ? 'linear-gradient(#090325 0 0) padding-box, linear-gradient(117.8deg, #5b3ad2 0%, #5fbbe3 101.07%) border-box'
      : 'linear-gradient(93.14deg, #5b3ad2 -1.08%, #5fbbe3 101.45%)'};

  cursor: pointer;
`;

export const TextCookies = styled(Text)`
  ${FONT({})}
`;
