import styled from 'styled-components';
import { COLORS } from '../../assets';
import { DesktopBreakpoint } from '../../helpers';
import { FLEX, FONT } from '../../utils';
import { Section } from '../Styled';

export const SectionContactForm = styled(Section)`
  padding-bottom: 40px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    padding-bottom: 120px;
  }
`;
export const StyledFormWraper = styled.div`
  position: relative;
  background: rgba(25, 13, 51);
  border-radius: 12px;
  overflow: hidden;
  opacity: 1;
  padding: 40px 20px;
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 994px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    display: flex;
    flex-direction: column;
    min-width: ${DesktopBreakpoint.px};
    border-radius: 20px;
    padding: 52px 70px 70px;
    background: rgba(25, 13, 51, 0.8);
  }
`;
export const StyledDataList = styled.datalist`
  display: block;
`;
export const TitleForm = styled.h3`
  ${FONT({ weight: '600', size: '17px' })};
  color: ${COLORS.gray};
  margin-top: 14px;
  margin-bottom: 20px;
  color: ${COLORS.gray};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '600', size: '24px' })};
    margin-top: 19px;
    margin-bottom: 29px;
  }
`;
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    justify-content: space-between;
  }
`;
export const FormDiscription = styled.span`
  ${FONT({ weight: '400', size: '14px' })}
  line-height:1.21;
  color: ${COLORS.gray};

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '600' })}
    width:702px;
    height: 34px;
  }
`;
export const InputBoxWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  &::before {
    display: block;
    content: ' ';
    width: 166px;
    height: 166px;
    border-radius: 50%;
    position: absolute;
    background: #472fb5;
    opacity: 0.7;
    filter: blur(160px);
    top: 0;
    left: 0;
  }
  &::after {
    display: block;
    content: ' ';
    width: 154px;
    height: 154px;
    border-radius: 50%;
    position: absolute;
    background: #472fb5;
    opacity: 0.7;
    filter: blur(160px);
    bottom: 10%;
    right: -20%;
  }

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 59px;

    &::before {
      display: block;
      content: ' ';
      width: 383px;
      height: 383px;
      border-radius: 50%;
      position: absolute;
      background: #472fb5;
      opacity: 0.7;
      filter: blur(250px);
      top: -30%;
      left: -20%;
    }
    &::after {
      display: block;
      content: ' ';
      width: 383px;
      height: 383px;
      border-radius: 50%;
      position: absolute;
      background: #472fb5;
      opacity: 0.7;
      filter: blur(250px);
      bottom: 10%;
      right: -20%;
    }
  }
`;
export const StyledCyanContainer = styled.span`
  color: ${COLORS.blue};
`;
export const FormImgMob = styled.img`
  width: 70px;
  height: 70px;
`;
export const FormImg = styled.img`
  width: 119px;
  height: 119px;
`;

export const TopWrapper1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 2fr);
    grid-column-gap: 59px;
    grid-row-gap: 30px;
  }
`;
export const TopWrapper3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px;
  }
`;
export const TopWrapper2 = styled.div`
  ${FLEX({ direction: 'column', align: 'center' })};
  gap: 30px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({})};
  }
`;
