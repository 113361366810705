import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as Yuotube } from '../../assets/icons/youtube.svg';
export const items = [
  {
    Icon: Linkedin,
    title: 'linkedin',
    href: 'https://www.linkedin.com/company/cyb3ractive/',
  },
  {
    Icon: Twitter,
    title: 'twitter',
    href: 'https://twitter.com/cyb3ractive',
  },
  {
    Icon: Facebook,
    title: 'facebook',
    href: 'https://www.facebook.com/Cyb3rActive/',
  },
  {
    Icon: Yuotube,
    title: 'youtube',
    href: 'https://www.youtube.com/channel/UCvUYNyUlzmO48gNvGZSMm1A',
  },
];
