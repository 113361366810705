import styled from 'styled-components';
import { FLEX } from '../../utils';
import BgAbstractMob from '../../assets/bg/bg-abstract-mob.png';
import BgAbstract from '../../assets/bg/bg-abstract.png';
import { DesktopBreakpoint } from '../../helpers';

export const AboutMobImg = styled.img`
  width: 241px;
  height: 251px;
`;
export const AboutImg = styled.img`
  width: 444px;
  height: 464px;
`;
export const RightSideBox = styled.div`
  ${FLEX({ direction: 'column', justify: 'center', align: 'flex-start' })};
  margin-bottom: 20px;
  overflow-x: hidden;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: 636px;
    height: auto;
    margin-bottom: 0;
  }
`;
export const LeftSideBox = styled.div<{
  isVisible: boolean;
}>`
  display: flex;
  overflow-x: hidden;
  transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  display: ${props => (props.isVisible ? 'static' : 'none')};
  animation: ${props => props.isVisible && 'scale 3s 1'};
  @keyframes scale {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const SectionAbout = styled.section`
  display: flex;
  justify-content: 'flex-start';
  position: relative;

  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    padding-top: 40px;
    &::before {
      display: block;
      content: ' ';
      width: 100%;
      height: 663px;
      position: absolute;
      background-image: url('${BgAbstractMob}');
      background-repeat: no-repeat;
      background-position: center bottom;
      top: 35%;
      left: 0;
    }
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ justify: 'space-between' })};
    padding-top: 118px;
    &::before {
      display: block;
      content: ' ';
      width: 100%;
      height: 663px;
      position: absolute;
      background-image: url('${BgAbstract}');
      background-repeat: no-repeat;
      background-position: center bottom;
      top: 60%;
      left: 0;
    }
  }
`;

export const LayoutWrapper = styled.div`
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ direction: 'column', justify: 'center' })};
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ justify: 'space-between' })};
  }
`;
