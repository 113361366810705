import { Field } from 'formik';
import styled from 'styled-components';
import { COLORS } from '../../assets';
import { DesktopBreakpoint } from '../../helpers';
import { FLEX, FONT } from '../../utils';

export const ModalButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background: transparent;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    top: 20px;
    right: 10px;
  }
`;

export const ModalContent = styled.div<{ className: string }>`
  position: absolute;
  z-index: 999;
  margin: auto;
  top: 89px;
  padding: 40px 20px;
  border-radius: 12px;
  background: ${COLORS.white};
  width: 100%;
  max-width: 343px;
  opacity: 1;
  transform: ${props =>
    props.className === 'active' ? 'scale(0)' : 'scale(1)'};
  transition: 0.3s linea all;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    max-width: 636px;
    min-height: 636px;
    padding: 60px 110px;
    top: 96px;
    border-radius: 20px;
  }
`;
export const TitleForm = styled.h2`
  font-weight: 700;
  font-size: 22px;
  line-height: 1.21;
  text-align: center;
  color: #12062c;
  margin-bottom: 20px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    font-weight: 700;
    font-size: 36px;
    line-height: 1.22;
    margin-bottom: 30px;
  }
`;

export const FormModal = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const InputBoxWraper = styled.div`
  ${FLEX({ direction: 'column' })};
  flex-wrap: wrap;
  gap: 30px;
`;
export const CheckBoxWraper = styled.div`
  ${FLEX({ direction: 'column' })};
  margin-top: 20px;
`;

export const LabelCheckbox = styled.label`
  ${FONT({})};
  line-height: 1.21;
  color: #12062c;
`;

export const CheckBoxField = styled(Field)`
  width: 20px;
  height: 20px;
  border: 2px solid #12062c;
  border-radius: 2px;
  &:checked + ${LabelCheckbox} {
    background-color: #00283c;
    border-color: transparent;
    color: ${COLORS.white};
  }
`;
