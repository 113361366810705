import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Text } from '../../components/Styled';
import { StyledListPurposes, ListItem } from './styled';
import { TListProps } from './types';

export const ListPurposes: React.FC<TListProps> = ({ items }) => {
  return (
    <StyledListPurposes>
      {items.map(item => (
        <ListItem key={uuidv4()}>
          <Text>{item}</Text>
        </ListItem>
      ))}
    </StyledListPurposes>
  );
};
