import { TRoutesTree } from './types';
import { PrivacyPage } from '../pages/PrivacyPage';
import { TermsPage } from '../pages/TermsPage';
import { HomePage } from '../pages/HomePage';
import { SuccessPage } from '../pages/SuccessPage';

export const RoutesTree: TRoutesTree = {
  home: {
    name: 'home',
    path: '/',
    component: HomePage,
  },
  privacy: {
    name: 'privacy',
    path: '/privacy',
    component: PrivacyPage,
  },
  terms: {
    name: 'terms',
    path: '/terms',
    component: TermsPage,
  },
  success: {
    name: 'success',
    path: '/success',
    component: SuccessPage,
  },
};
