import { createGlobalStyle } from 'styled-components';
import { COLORS } from '../assets';

export default createGlobalStyle`
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}
html,
body,
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}
body {
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: ${COLORS.white};
  overflow-x: hidden;
  background-color: #12062c;
  /* backdrop-filter: blur(12px); */
  animation-duration: 1000ms;
  scroll-behavior: smooth;
}
`;
