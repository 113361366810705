import styled from 'styled-components';
import { COLORS } from '../../assets';
import { DesktopBreakpoint } from '../../helpers';
import { FONT, FLEX } from '../../utils';

export const StyledCardList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    gap: 14px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 14px;
  }
`;
export const CardItem = styled.li`
  ${FLEX({ justify: 'flex-start', align: 'flex-start' })};
  min-height: 136px;
  padding: 20px;
  background: #1e1138;
  backdrop-filter: blur(40px);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.5s linear all;
  &:hover,
  &:focus {
    box-shadow: 5px 5px 5px 5px #5b3ad2;
  }

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: 406px;
    min-height: 159px;
  }
`;
export const StyledFAIcon = styled.div`
  ${FLEX({})};
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  background: #12062b;
  border: none;
  border-radius: 50%;
  text-align: center;
  margin-right: 14px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: 83px;
    height: 83px;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const CardContext = styled.div`
  ${FLEX({ direction: 'column', justify: 'flex-start', align: 'flex-start' })};
`;
export const TextCard = styled.span`
  ${FONT({})};
  color: ${COLORS.gray};
  margin-top: 8px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ size: '16px' })};
  }
  line-height: 1.21;
`;
export const Image = styled.img`
  width: 60px;
  height: 60px;
`;
export const DecorateWrapper = styled.div`
  position: relative;
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    /* &::before {
      content: ' ';
      width: 227px;
      height: 227px;
      display: block;
      border-radius: 50%;
      position: absolute;
      background: #5b40d3;
      opacity: 0.3;
      filter: blur(230px);
      top: -10%;
      right: -30%;
      z-index: -1;
    }

    &::after {
      content: ' ';
      width: 227px;
      height: 227px;
      display: block;
      border-radius: 50%;
      position: absolute;
      background: #5b40d3;
      opacity: 0.3;
      filter: blur(230px);
      bottom: -5%;
      left: -30%;
      z-index: -1;
    } */
  }

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    /* &::before {
      content: ' ';
      width: 538px;
      height: 538px;
      display: block;
      border-radius: 50%;
      position: absolute;
      background: #5b40d3;
      opacity: 0.3;
      filter: blur(230px);
      top: -10%;
      right: -30%;
      z-index: -1;
    }
    &::after {
      content: ' ';
      width: 538px;
      height: 538px;
      display: block;
      border-radius: 50%;
      position: absolute;
      background: #5b40d3;
      opacity: 0.3;
      filter: blur(230px);
      bottom: -30%;
      left: -30%;
      z-index: -1;
    } */
  }
`;
