import styled from 'styled-components';
import { Field } from 'formik';
import { COLORS } from '../../../assets/colors';
import { FONT } from '../../../utils/css';
import { DesktopBreakpoint } from '../../../helpers';
export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    margin: 0 auto;
  }
`;
export const Label = styled.label<{ className: string }>`
  ${FONT({ size: '16px' })}
  line-height: 1.19;
  color: ${props => (props.className === 'contact' ? '#D3D3D3' : '#68607C')};
  position: absolute;
  transform: translateY(2rem);
  top: -18px;
  left: 18px;
  transform-origin: left top;
  cursor: text;
  transition: all 0.3s;
  pointer-events: none;
  border: 1px solid transparent;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ size: '18px' })}
    line-height: 1.21;
  }
`;

export const StyledInput = styled(Field)<{ className: string }>`
  height: 49px;
  padding: 15px 16px 4px 16px;
  ${FONT({ size: '16px' })}
  line-height: 1.19;
  color: ${props => (props.className === 'contact' ? '#ffffff' : '#12062C')};
  border: ${props =>
    props.className === 'contact' ? '2px solid #68607c' : '2px solid #D3D3D3'};

  border-radius: 6px;
  outline: none;
  background: transparent;
  transition: 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus,
  &:not(:placeholder-shown) {
    border-color: ${props =>
      props.className === 'contact' ? '#ffffff' : '#68607C'};
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: transparent;
  }
  &:focus,
  &::not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    ${FONT({ size: '12px' })}
    padding: 0;
    cursor: text;
    color: #5fbbe3;
    transform: translateY(20px);
    @media screen and (min-width: ${DesktopBreakpoint.px}) {
      ${FONT({})}
      line-height: 1.21;
    }
  }
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    width: 100%;
    max-width: 303px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: ${props => (props.className === 'contact' ? '346px' : '416px')};
  }
`;

export const Error = styled.span`
  position: absolute;
  top: 52px;
  left: 10px;
  width: 100%;
  height: 5px;
  ${FONT({
    size: '12px',
    weight: '700',
    color: COLORS.red,
  })}
`;
export const StyledTextarea = styled(Field)`
  width: 100%;
  max-height: 120px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;

  padding: 15px 16px;
  ${FONT({ size: '16px' })}
  line-height: 1.19;
  color: ${COLORS.white};
  border: 2px solid #68607c;
  border-radius: 6px;
  outline: none;
  background: transparent;
  transition: 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus,
  &:not(:placeholder-shown) {
    border-color: ${COLORS.white};
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: transparent;
  }
  &:focus,
  &::not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    ${FONT({ size: '12px' })};
    line-height: 1.19;
    padding: 0;
    cursor: text;
    color: #5fbbe3;
    transform: translateY(20px);
  }
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    max-width: 303px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({})};
    max-width: 346px;
  }
`;
