import React from 'react';
import {
  CardContext,
  CardItem,
  StyledCardList,
  StyledFAIcon,
  TextCard,
} from './styled';
import { Title4 } from '../Styled';
import { v4 as uuidv4 } from 'uuid';
import { TCardProps } from './types';

export const CardList: React.FC<TCardProps> = ({ items }) => {
  return (
    <>
      <StyledCardList>
        {items.map(({ Icon, ...item }) => (
          <CardItem key={uuidv4()}>
            <StyledFAIcon>
              <Icon />
            </StyledFAIcon>
            <CardContext>
              <Title4>{item.title}</Title4>
              <TextCard>{item.text} </TextCard>
            </CardContext>
          </CardItem>
        ))}
      </StyledCardList>
    </>
  );
};
