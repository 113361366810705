import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CardItem, CardMenu } from './styled';
import { TCardProps } from './types';

export const CardList: React.FC<TCardProps> = ({ items }) => {
  return (
    <CardMenu>
      {items.map(item => (
        <CardItem key={uuidv4()}>{item}</CardItem>
      ))}
    </CardMenu>
  );
};
