import React, { createContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Container } from '../Styled';
import links from './moca.json';
import { ReactComponent as IconBurger } from '../../assets/icons/burger.svg';
import { ReactComponent as IconClose } from '../../assets/icons/close.svg';
import { items } from '../Footer/data';
import {
  LogoIcon,
  LogoNameIcon,
  NavigationBox,
  HeaderBox,
  TopbarWrapper,
  ButtonMenu,
} from './styled';
import { MenuList } from './MenuList';
import { MenuLink } from './MenuLink';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Button } from '../common/Button';
import { SocialListLink } from '../Footer/SocialListLink';
import { Modal } from '../Modal';
import { DesktopBreakpoint } from '../../helpers';

export const MenuContext = createContext({
  isMenuOpen: true,
  toggleMenu: () => {},
});

export const Header: React.FC = () => {
  const [isMenuOpen, toggleMenu] = useState(false);
  const [active, setActive] = useState(false);
  const size = useWindowDimensions().width;

  let history = useHistory();
  const path = history.location.pathname;

  function toggleMenuMode() {
    toggleMenu(!isMenuOpen);
  }
  const HandleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    toggleMenu(!isMenuOpen);
  };
  const handleLink = () => {
    toggleMenu(!isMenuOpen);
  };
  const handleModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActive(!active);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <HeaderBox>
        <Container>
          <NavigationBox>
            <TopbarWrapper>
              <NavLink
                to="/"
                aria-label="logo"
                onClick={e => {
                  e.preventDefault();
                  window.location.replace('/');
                }}
              >
                <LogoIcon />
              </NavLink>
              <NavLink
                to="/"
                aria-label="logo-text"
                onClick={e => {
                  e.preventDefault();
                  window.location.replace('/');
                }}
              >
                <LogoNameIcon />
              </NavLink>
            </TopbarWrapper>
            <MenuContext.Provider
              value={(isMenuOpen as Boolean, toggleMenuMode as any)}
            >
              {path === '/' && (
                <MenuLink className={isMenuOpen ? 'is-open' : ''}>
                  <MenuList links={links} handleLink={handleLink} />
                  {isMenuOpen && size <= DesktopBreakpoint.value && (
                    <SocialListLink items={items} />
                  )}
                </MenuLink>
              )}
              <Button
                name="primary"
                type="button"
                title="Early access"
                width={size <= DesktopBreakpoint.value ? '167px' : '196px'}
                height={size <= DesktopBreakpoint.value ? '36px' : '42px'}
                onClick={handleModal}
              />
              {path === '/' && (
                <ButtonMenu type="button" onClick={HandleClickMenu}>
                  {isMenuOpen ? <IconClose /> : <IconBurger />}
                </ButtonMenu>
              )}
            </MenuContext.Provider>
          </NavigationBox>
        </Container>
      </HeaderBox>
      {active && (
        <Modal
          active={active}
          handleClick={handleModal}
          setActive={setActive}
        />
      )}
    </>
  );
};
