import {
  TFlexConstructorArgs,
  TFontConstructorArgs,
  TCSSConstructor,
  TBorderConstructorArgs,
} from './types';

export const FONT: TCSSConstructor<TFontConstructorArgs> = ({
  color = 'wthite',
  family = 'Inter, sans-serif',
  weight = '400',
  size = '14px',
}) => {
  return `
    font-family: ${family};
    font-weight: ${weight};
    font-size: ${size};
    color: ${color};
  `;
};

export const FLEX: TCSSConstructor<TFlexConstructorArgs> = ({
  direction = 'row',
  justify = 'center',
  align = 'center',
}) => {
  return `
    display: flex;
    justify-content: ${justify};
    flex-direction: ${direction};
    align-items: ${align};
  `;
};
export const BORDER: TCSSConstructor<TBorderConstructorArgs> = ({
  width = 6,
  style = 'solid',
  color = 'transparent',
}) => {
  return `
    border: ${width}px ${style} ${color};
  `;
};
