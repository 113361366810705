import React from 'react';
import { CardList } from './CardList';
import { StyledCard, TitleCard } from './styled';
import { TCardList } from './types';

export const Card: React.FC<TCardList> = ({ title, items }) => {
  return (
    <StyledCard>
      <TitleCard>{title}</TitleCard>
      <CardList items={items} />
    </StyledCard>
  );
};
