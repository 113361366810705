import React from 'react';
import { DesktopBreakpoint } from '../../helpers';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Divider } from '../common/Divider';
import { Container, Section, Text, Title2 } from '../Styled';
import { CardList } from './CardList';
import { items } from './data';
import { DecorateWrapper } from './styled';

export const TechnologySection = () => {
  const { width } = useWindowDimensions();
  return (
    <Section id="technology">
      <Container>
        <DecorateWrapper>
          <Title2>Our Technology</Title2>
          {width <= DesktopBreakpoint.value ? (
            <Divider height={20} />
          ) : (
            <Divider height={29} />
          )}
          <Text>
            The CyberActive Autonomous Detection and Response platform was
            surgically architected and purpose-built from the ground up as a
            next-gen autonomous cybersecurity operational platform. Our
            technology seamlessly integrates into any environment and enables a
            turnkey solution that delivers day-1 automated threat prevention.
            The CyberActive engine analyzes all event telemetry in real-time,
            conducts Software-DNA-Mapping based malware analysis in
            milliseconds, and automatically remediates threats across the
            infrastructure at near real-time speeds.
          </Text>
          <CardList items={items} />
        </DecorateWrapper>
      </Container>
    </Section>
  );
};
