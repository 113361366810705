import React, { FC, useRef } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContactSchema } from './ContactSchema';
import { InputField } from '../common/Input/InputField';
import { Button } from '../common/Button';
import { TFormProps } from './types';
import { KeyCaptcha } from '../../assets/enums';
import formImgMob from '../../assets/images/form-img-mob.png';
import formImg from '../../assets/images/form-img-mob.png';
import {
  StyledFormWraper,
  StyledForm,
  TitleForm,
  FormDiscription,
  InputBoxWraper,
  FormImgMob,
  FormImg,
  TopWrapper1,
  TopWrapper2,
  TopWrapper3,
  SectionContactForm,
} from './styles';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  Container,
  Title2,
  ContainerWrapper,
  TopWrapper,
  LinkTo,
} from '../Styled';
import { TextareaField } from '../common/Input/TextareaField';
import { Divider } from '../common/Divider';
import { DesktopBreakpoint } from '../../helpers';
import { sendGetUpdatesEmail } from '../../helpers/sendEmail';

const initialValues: TFormProps = {
  name: '',
  lastName: '',
  email: '',
  company: '',
  country: '',
  state: '',
  phone: '',
  description: '',
};

type TContactForm = {
  blockRef: any;
};

export const ContactForm: FC<TContactForm> = ({ blockRef }) => {
  const size = useWindowDimensions().width;
  const recaptchaRef = useRef<ReCAPTCHA>();

  const handleSubmitData = (
    values: TFormProps,
    { resetForm }: { resetForm: any },
  ) => {
    if (recaptchaRef?.current?.getValue() === '') {
      toast.error('Please verify that you are a Human.');
      return;
    }

    sendGetUpdatesEmail(values)
      .then(_res => {
        toast.success(`Thank you ${values.name}! Your submition has been sent`);
        resetForm({ values: initialValues });
        window.location.replace('/#/success');
      })
      .catch(_error => {
        toast.error(
          'Hmm, something went wrong. Try emailing us directly at info@cyberactive.com',
        );
      });
  };
  return (
    <SectionContactForm id="contact" ref={blockRef}>
      <Container>
        <StyledFormWraper>
          <ContainerWrapper>
            <TopWrapper>
              <Title2>Get Updates</Title2>
              <TitleForm>Let’s Stay in Touch</TitleForm>
            </TopWrapper>
            <TopWrapper>
              {size <= DesktopBreakpoint.value ? (
                <FormImgMob src={formImgMob} alt="picture cyan" />
              ) : (
                <FormImg src={formImg} alt="picture cyan" />
              )}
            </TopWrapper>
          </ContainerWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={ContactSchema}
            onSubmit={handleSubmitData}
          >
            {({ values, handleChange, handleSubmit }) => (
              <StyledForm onSubmit={handleSubmit} id="contact">
                <InputBoxWraper>
                  <TopWrapper1>
                    <InputField
                      type="text"
                      name="name"
                      label="FirstName"
                      onChange={handleChange}
                      value={values.name}
                    />
                    <InputField
                      type="text"
                      name="lastName"
                      label="Last name"
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    <InputField
                      type="text"
                      name="email"
                      label="E-mail"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <InputField
                      type="text"
                      name="company"
                      label="Company"
                      onChange={handleChange}
                      value={values.company}
                    />
                    <InputField
                      type="text"
                      name="country"
                      label="Country"
                      value={values.country}
                      onChange={handleChange}
                      list="country"
                    />
                    <InputField
                      type="text"
                      name="state"
                      label="State"
                      value={values.state}
                      onChange={handleChange}
                    />
                  </TopWrapper1>
                  <TopWrapper3>
                    <InputField
                      type="text"
                      name="phone"
                      label="Phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                    <TextareaField
                      id="contact"
                      name="description"
                      label="How we can help?"
                      value={values.description}
                    />
                  </TopWrapper3>
                </InputBoxWraper>
                <Divider height={14} />
                <TopWrapper2>
                  <FormDiscription>
                    By providing my contact information, I agree to the 
                    <LinkTo
                      to="/privacy"
                      target="_blank"
                      aria-label="link page"
                      title="Go to Privacy Policy"
                    >
                      Privacy Policy
                    </LinkTo>
                     and consent to receive communications from CyberActive at
                    the contact information provided.
                  </FormDiscription>
                  <ReCAPTCHA
                    ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                    sitekey={KeyCaptcha.SITE_KEY}
                    stoken={KeyCaptcha.SECURITY_KEY}
                  />

                  <Button
                    type="submit"
                    name="secondary"
                    title="Send"
                    width={size <= DesktopBreakpoint.value ? '303px' : '346px'}
                    height="46px"
                  />
                </TopWrapper2>
              </StyledForm>
            )}
          </Formik>
        </StyledFormWraper>
      </Container>
    </SectionContactForm>
  );
};
