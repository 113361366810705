import styled from 'styled-components';
import { COLORS } from '../../assets';
import { FLEX, FONT } from '../../utils';
import BgLinesMob from '../../assets/bg/illustrationLines-mob.png';
import BgLines from '../../assets/bg/illustrationLines.png';
import { Title1 } from '../Styled';
import { DesktopBreakpoint } from '../../helpers';
export const SectionHero = styled.section`
  display: flex;
  justify-content: 'flex-start';
  position: relative;

  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    padding-top: 26px;
    padding-bottom: 100px;
    background-image: url('${BgLinesMob}');
    background-repeat: no-repeat;
    background-position: center bottom;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 102px;
    padding-bottom: 121px;
    background-image: url('${BgLines}');
    background-repeat: no-repeat;
    background-position: center bottom;
  }
`;
export const HeroBox = styled.div`
  ${FLEX({ direction: 'column', justify: 'space-between' })}
  position: relative;

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({
      direction: 'row-reverse',
      justify: 'space-between',
    })}
  }
`;
export const HeroRightSide = styled.div`
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    display: flex;
    justify-content: center;
    margin-bottom: 41px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    padding: 0;
    margin: 0;
  }
`;
export const HeroPictureBox = styled.div`
  position: relative;
  overflow: hidden;
  width: 240.84px;
  height: 257.92px;

  &::after {
    position: absolute;
    display: block;
    content: ' ';
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    top: 85px;
    left: 110px;
    background: radial-gradient(
      34.73% 32.97% at 49.68% 49.27%,
      #ebebeb 0%,
      #c5c5c5 14%,
      #a2a2a2 24%,
      #636363 39%,
      #3d3d3d 55%,
      #2b2b2b 65%,
      #1f1f1f 75%,
      #0c0c0c 87%,
      #000000 100%
    );
    background-blend-mode: color-dodge;
    mix-blend-mode: color-dodge;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: 501px;
    height: 537px;
    &::after {
      width: 171px;
      height: 156px;
      border-radius: 50%;
      top: 160px;
      left: 200px;
      background: radial-gradient(
        34.73% 32.97% at 49.68% 49.27%,
        #ebebeb 0%,
        #c5c5c5 14%,
        #a2a2a2 24%,
        #636363 39%,
        #3d3d3d 55%,
        #2b2b2b 65%,
        #1f1f1f 75%,
        #0c0c0c 87%,
        #000000 100%
      );

      background-blend-mode: color-dodge;
      mix-blend-mode: color-dodge;
    }
  }
`;
export const HeroLeftSide = styled.div`
  ${FLEX({ direction: 'column', justify: 'flex-start' })};

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({
      direction: 'column',
      justify: 'flex-start',
      align: 'flex-start',
    })};
    width: 636px;
    height: 400px;
    padding: 0;
    margin: 0;
  }
`;
export const HeroTitle = styled.h1`
  ${FONT({ weight: '700', size: '24px' })};
  text-align: 'right';
  line-height: 1.19;
  margin-bottom: 20px;
  color: ${COLORS.white};
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '700', size: '42px' })};
    line-height: 1.14;
    margin-bottom: 30px;
  }
`;
export const HeroDescription = styled.span`
  ${FONT({ size: '16px' })};
  text-align: 'right';
  line-height: 1.21;
  color: ${COLORS.gray};
  margin-bottom: 21px;

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ size: '18px' })}
    line-height: 1.36;
    margin-bottom: 15px;
  }
`;

export const CyberImgMob = styled.img`
  width: 241px;
  height: 258px;
`;
export const CyberImg = styled.img`
  width: 501px;
  height: 537px;
`;
export const TitleHero1 = styled(Title1)`
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -webkit-animation: text 1s steps(20, end);
  animation: text 1s steps (20, end);
  animation-timing-function: linear;
  margin-bottom: 0;
  @keyframes text {
    from {
      width: 0;
      opacity: 0;
    }
    to {
      width: 100%;
      opacity: 1;
    }
  }
`;
export const TitleHero2 = styled.h2`
  position: relative;
  white-space: nowrap;
  width: 100%;
  ${FONT({ weight: '700', size: '24px' })};
  text-align: 'right';
  line-height: 1.21;
  margin-bottom: 20px;
  color: ${COLORS.white};
  -webkit-animation: type 1s;
  animation: type 1s;
  animation-timing-function: linear;
  margin-bottom: 20px;

  @keyframes type {
    0% {
      opacity: 0;
      top: 20px;
    }
    50% {
      opacity: 0.1;
      top: 10px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '700', size: '42px' })};
    line-height: 1.14;
    margin-bottom: 30px;
    width: 636px;
  }
`;
