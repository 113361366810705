import React from 'react';
import { useField } from 'formik';
import { TTextareaProps } from './types';
import { TextArea } from './Textarea';

type TextareaFieldProps = Omit<TTextareaProps, 'isInvalid' | 'errorText'>;

export const TextareaField: React.FC<TextareaFieldProps> = ({
  name,
  ...rest
}) => {
  const [field, meta] = useField(name);
  return (
    <TextArea
      {...field}
      {...rest}
      onChange={field.onChange}
      isValid={Boolean(meta.error && meta.touched)}
      error={String(meta.error)}
    />
  );
};
