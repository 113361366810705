export type TMediaQueries = {
  [breakpoint in TMediaQueryBreakpoints]: string;
};

export type TMediaBreakpoints = {
  [breakpoint in Exclude<TMediaQueryBreakpoints, 'xs'>]: number;
};

export type TMediaQueryBreakpoints = 'xs' | 'md';

export class Breakpoint {
  get px() {
    return `${this.value}px`;
  }
  constructor(readonly value: number) {}
}