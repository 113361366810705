import React, { useRef } from 'react';
import { Divider } from '../common/Divider';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  Container,
  LayoutWrapper,
  Section,
  Text,
  Title,
  Title3,
} from '../Styled';
import { LeftSideBar, RightSideBar } from './styled';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { DesktopBreakpoint } from '../../helpers';

export const WhyCyberSection: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const { width } = useWindowDimensions();
  return (
    <>
      <Section id="cyber" ref={ref}>
        <Container>
          <LayoutWrapper>
            <LeftSideBar isVisible={isVisible}>
              <Title>
                Why
                <br />
                CyberActive
              </Title>
            </LeftSideBar>
            <RightSideBar>
              <Title3>Smarter. Better. Faster. Autonomous.</Title3>
              {width <= DesktopBreakpoint.value ? (
                <Divider height={20} />
              ) : (
                <Divider height={30} />
              )}
              <Text>
                The CyberActive patent-pending autonomous AI technology is
                designed to detect and deter the most sophisticated cyberattacks
                in today’s complex network environments. In the era of a
                continuously expanding attack surface, exponentially increasing
                network complexity, and the advancement of cyberattack
                sophistication, businesses can no longer rely just on
                traditional security methods such as EDR, SIEM/XDR, UTM devices,
                and Sandbox solutions.
                <br />
                <br /> Human responses cannot match the pace of today’s
                AI-driven cyberattacks. Cybercriminals leverage enhanced attack
                methods such as exploiting complex network vulnerabilities,
                launching sophisticated malicious code, and advanced data
                exfiltration tactics to breach organizations at will.
                <br />
                <br /> CyberActive analyzes large-scale and complex telemetry
                across all systems and utilizes the power of Self-Learning
                Artificial Intelligence, Deep Learning Predictive Algorithms,
                and the next evolution Automation. Armed with these advanced
                state-of-the-art technologies, CyberActive can outsmart
                sophisticated attacks, exceed the pace of cybercriminals, and
                deter cyberattacks in real-time before a breach takes place.
              </Text>
            </RightSideBar>
          </LayoutWrapper>
        </Container>
      </Section>
    </>
  );
};
