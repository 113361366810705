import React from 'react';
import {
  StyledCheckbox,
  LabelCheckbox,
  CheckBoxWraper,
  ErrorCheck,
} from './styled';
import { TCheckboxProps } from './types';

export const Checkbox: React.FC<TCheckboxProps> = ({
  name,
  disabled,
  htmlFor = name,
  label,
  isInvalid,
  errorText,
  onChange,
  ...restProps
}) => {
  return (
    <CheckBoxWraper>
      <StyledCheckbox
        name={name}
        placeholder=" "
        type="checkbox"
        id={htmlFor}
        disabled={disabled}
        value={htmlFor}
        {...restProps}
      />
      <LabelCheckbox htmlFor={htmlFor}>{label}</LabelCheckbox>
      {isInvalid && errorText && <ErrorCheck>{errorText}</ErrorCheck>}
    </CheckBoxWraper>
  );
};
