import styled from 'styled-components';
import { COLORS } from '../../../assets/colors';
import { FONT, FLEX } from '../../../utils/css';
import { ReactComponent as ScrollIcon } from '../../../assets/icons/scroll.svg';
import { DesktopBreakpoint } from '../../../helpers';

export const Lable = styled.span`
  height: 50px;
  ${FONT({ weight: '600', size: '15px' })}
  line-height: 1.2;
  color: ${COLORS.white};
  cursor: pointer;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    font-size: 16px;
    line-height: 1.21;
  }
`;

export const StyledButton = styled.button<{
  name: string;
  width: string;
  height: string;
}>`
  width: ${props => props.width};
  height: ${props => props.height};
  white-space: nowrap;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  flex-shrink: 1;
  transition: 0.3s linear;

  &:hover,
  &:focus {
    -webkit-box-shadow: 5px 5px 5px 5px #5b3ad2;
    box-shadow: 5px 5px 5px 5px #5b3ad2;
  }
  border: ${props =>
    props.name === 'primary' ? '2px solid transparent' : 'none'};

  background: ${props =>
    props.name === 'primary'
      ? 'linear-gradient(#090325 0 0) padding-box, linear-gradient(117.8deg, #5b3ad2 0%, #5fbbe3 101.07%) border-box'
      : 'linear-gradient(93.14deg, #5b3ad2 -1.08%, #5fbbe3 101.45%)'};

  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    width: 100%;
    max-width: ${props => props.width};
  }
`;
export const StyledScrollIcon = styled(ScrollIcon)`
  width: 30px;
  height: 30px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: 34px;
    height: 34px;
  }
`;
export const StyledButtonScrollBack = styled.div`
  ${FLEX({})};
  position: absolute;
  bottom: 9%;
  right: 0;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border: none;
  border-radius: 50%;
  text-align: center;
  background: #271f37;
  cursor: pointer;
  z-index: 3;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    width: 60px;
    height: 60px;
    bottom: 6%;
    right: 0;
  }
`;
