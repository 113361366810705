import React from 'react';
import { Lable, StyledButton } from './styled';
import { TButtonProps } from './types';

export const Button: React.FC<TButtonProps> = ({
  title = '',
  type = 'button',
  onClick,
  children,
  name = 'primary',
  width = '0px',
  height = '0px',
  ...props
}) => {
  return (
    <StyledButton
      name={name}
      type={type}
      width={width}
      height={height}
      onClick={onClick}
      {...props}
    >
      <Lable>
        {title}
        {children}
      </Lable>
    </StyledButton>
  );
};

export default Button;
