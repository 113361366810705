import emailjs from '@emailjs/browser';
import { TFormProps } from '../components/ContactForm/types';
import { TFormModalProps } from '../components/Modal/types';

const service_id = 'service_aj6dmd8';
const template_id_early_access = 'Cyberactive-Early-Access';
const template_id_get_updates = 'Cyberactive-Get-Updates';
const public_key = '-lWRlafP17QHs-vxX';

export const initEmail = () => emailjs.init(public_key);

export const sendEarlyAccessEmail = async (template_params: TFormModalProps) =>
  emailjs.send(service_id, template_id_early_access, template_params);

export const sendGetUpdatesEmail = async (template_params: TFormProps) =>
  emailjs.send(service_id, template_id_get_updates, template_params);
