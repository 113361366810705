import React, { FC } from 'react';
import { Label, StyledInputWrapper, StyledTextarea } from './styled';
import { TTextareaProps } from './types';

export const TextArea: FC<TTextareaProps> = ({
  name,
  label,
  error,
  isValid,
  value,
  className,
  id,
  ...props
}) => {
  return (
    <StyledInputWrapper>
      <StyledTextarea
        autoComplete="off"
        name={name}
        value={value}
        className="contact"
        placeholder=" "
        id={id}
        component="textarea"
        rows="6"
        {...props}
      />
      {label && (
        <Label className="contact" htmlFor={name}>
          {label}
        </Label>
      )}
      {/* <Error>{!isValid ? error : ''}</Error> */}
    </StyledInputWrapper>
  );
};
