import * as Yup from 'yup';

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

export const ContactSchema = Yup.object({
  name: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  email: Yup.string().email("Email address isn't valid").required('Required'),
  company: Yup.string()
    .max(20, 'Must be 50 characters or less')
    .required('Required'),
  country: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(20, 'Must be 50 characters or less')
    .required('Required'),
  phone: Yup.string()
    .required('required')
    .matches(phoneRegExp, "Phone number isn't valid")
    .min(5, 'to short')
    .max(20, 'to long'),
  state: Yup.string().max(20, 'Must be 30 characters or less'),
  description: Yup.string().max(500, 'Must be 500 characters or less'),
});
