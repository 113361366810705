import styled from 'styled-components';

export const StyledBackdrop = styled.div<{ className: string }>`
  perspective: 600px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  display: flex;
  display: ${props => (props.className === 'active' ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.className === 'active' ? 'rgba(18, 6, 44, 0.8)' : 'transparent'};
  visibility: ${props => (props.className === 'active' ? 'visible' : 'hidden')};
  pointer-events: initial;
  overflow-y: auto;
  transition: 0.5s linea all;
`;
