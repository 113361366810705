import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuListFooter } from './MenuListFooter';
import { Container } from '../Styled';
import { useHistory } from 'react-router-dom';
import sections from '../Header/moca.json';
import { items } from './data';
import { pages } from './pagesData';
import { SocialListLink } from './SocialListLink';
import { NavigationLinksPages } from './NavigationLinksPage';
import { Button } from '../../components/common/Button';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  FooterTopBox,
  ImageLogo,
  ListWraper,
  StyledAssign,
  StyledIconVector,
  StyledFooterText,
  SectionFooter,
  FooterMidleBox,
  CopyWriteWraper,
  FooterBottomBox,
  LinkAssign,
} from './styled';
import { Divider } from '../common/Divider';
import { Modal } from '../Modal';
import { DesktopBreakpoint } from '../../helpers';

export const Footer: React.FC = () => {
  const [active, setActive] = useState(false);
  const size = useWindowDimensions().width;
  const history = useHistory();
  const path = history.location.pathname;
  const handleModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActive(!active);
    window.scrollTo(0, 0);
  };
  const currentYear = new Date().getFullYear();
  const copyright = `©${currentYear} CyberActive All Rights Reserved.`;
  return (
    <>
      <SectionFooter>
        <Container>
          <FooterTopBox>
            <NavLink
              to="/"
              aria-label="logo"
              onClick={e => {
                e.preventDefault();
                window.location.replace('/');
              }}
            >
              <ImageLogo />
            </NavLink>
            <ListWraper>
              <SocialListLink items={items} />
              {path === '/' && <MenuListFooter links={sections} />}
            </ListWraper>
          </FooterTopBox>
          {size < 1328 ? <Divider height={0} /> : <Divider height={41} />}
          <FooterMidleBox>
            <LinkAssign
              to="#"
              onClick={e => {
                window.location.href = 'mailto:info@cyberactive.com';
                e.preventDefault();
              }}
            >
              <StyledAssign>Investor Inquiries</StyledAssign>
              <StyledIconVector />
            </LinkAssign>
            <StyledFooterText>
              Join the CyberActive Launch Team
            </StyledFooterText>
            <Button
              name="primary"
              title="Early access"
              width={size <= DesktopBreakpoint.value ? '303px' : '266px'}
              height={size <= DesktopBreakpoint.value ? '36px' : '42px'}
              onClick={handleModal}
            />
          </FooterMidleBox>
          <FooterBottomBox>
            <CopyWriteWraper>{copyright}</CopyWriteWraper>
            <NavigationLinksPages items={pages} />
          </FooterBottomBox>
        </Container>
      </SectionFooter>
      {active && (
        <Modal
          active={active}
          handleClick={handleModal}
          setActive={setActive}
        />
      )}
    </>
  );
};
