import React from 'react';
import { RoutesTree } from './routes';
import { Route, Switch } from 'react-router-dom';
import { Header } from '../components/Header';
import 'react-toastify/dist/ReactToastify.css';
import { StyledToastContainer } from '../components/Styled';

const Router: React.FC = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route
          path={RoutesTree.privacy.path}
          component={RoutesTree.privacy.component}
        />
        <Route
          path={RoutesTree.terms.path}
          component={RoutesTree.terms.component}
        />
        <Route
          path={RoutesTree.success.path}
          component={RoutesTree.success.component}
        />
        <Route
          path={RoutesTree.home.path}
          component={RoutesTree.home.component}
        />
      </Switch>
      <StyledToastContainer position="bottom-left" autoClose={3000} />
    </>
  );
};

export default Router;
