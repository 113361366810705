import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { COLORS } from '../../assets';
import { FLEX, FONT } from '../../utils';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoName } from '../../assets/icons/logoName.svg';
import { DesktopBreakpoint } from '../../helpers';

export const HeaderBox = styled.header`
  display: flex;
  background: #090325;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4;

  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;
export const NavigationBox = styled.div`
  ${FLEX({ justify: 'space-between' })}
`;
export const TopbarWrapper = styled.div`
  ${FLEX({ justify: 'center' })};
  align-items: center;
  text-align: center;
`;

export const LogoIcon = styled(Logo)`
  width: 51px;
  height: 53px;
`;
export const LogoNameIcon = styled(LogoName)`
  width: 145px;
  height: 9px;
  fill: ${COLORS.white};
  transition: 0.3s linear;
  &:hover,
  &:focus {
    fill: ${COLORS.blue};
  }
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    display: none;
  }
`;
export const StyledMenuLink = styled.div<{ className: string }>`
  justify-content: space-between;
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 727px;
    top: calc(100% + 360px);
    left: 0;
    transform: translate(0, -50%);
    z-index: 120;
    opacity: ${props => (props.className === 'is-open' ? 1 : 0)};
    visibility: ${props =>
      props.className === 'is-open' ? 'visible' : 'hidden'};
    text-align: center;
    padding-top: 150px;
    background: #12062c;
    transition: 0.3s ease-out;
  }

  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    ${FLEX({})}
  }
`;

export const StyledMenuList = styled.ul`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    ${FLEX({ direction: 'column' })}
    height: auto;
    gap: 40px;
  }
`;
export const NavigationListItem = styled.li`
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    &:not(:last-child) {
      margin-right: 38px;
    }
  }
`;
export const NavLinkLogo = styled(NavLink)`
  display: 'inline-flex';
`;

export const StyledHashLink = styled(Link)`
  text-decoration: none;
  ${FONT({ size: '16px' })}
  line-height: 1.36;
  color: ${COLORS.white};
  transition: 0.3s linear;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${COLORS.blue};
  }
  &active {
    color: #5fbbe3;
    text-decoration-line: underline;
  }
  @media screen and (max-width: ${DesktopBreakpoint.px}) {
    ${FONT({ weight: '600', size: '24px' })}
    line-height: 1.2;
  }
`;

export const ButtonMenu = styled.button`
  position: relative;
  display: inline-flex;
  padding: 0;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  scale: 0.9;
  transition: 0.3s ease-out;
  &:hover {
    scale: 1;
  }
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    display: none;
  }
`;
