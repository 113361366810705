import React from 'react';
import { Divider } from '../../components/common/Divider';
import { Footer } from '../../components/Footer';
import { StyledAssign, StyledIconVector } from '../../components/Footer/styled';
import { Container, Section, Title } from '../../components/Styled';
import { LinkInvestor, StyledLogoIcon, SuccessWraperBox } from './styled';

export const SuccessPage: React.FC = () => {
  return (
    <>
      <Section>
        <Container>
          <SuccessWraperBox>
            <StyledLogoIcon />
            <Divider height={80} />
            <Title>
              Thank you. A representative will reach out to you shortly.
            </Title>
            <Divider height={80} />
            <LinkInvestor
              to="#"
              onClick={e => {
                e.preventDefault();
                window.location.href = 'mailto:info@cyberactive.com';
              }}
            >
              <StyledAssign>Investor Inquiries</StyledAssign>
              <StyledIconVector />
            </LinkInvestor>
          </SuccessWraperBox>
        </Container>
      </Section>
      <Footer />
    </>
  );
};
