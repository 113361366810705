export const listOne = [
  'Distributed Workforce',
  'Multi-Cloud Architecture',
  'Multi-Device and BYOD',
  'Branch office Security',
  'Data Center and Critical Infrastructure',
  'Wireless LAN',
  'IOT',
];

export const listTwo = [
  'AI-Enabled Attacks',
  'Sophisticated attack methods',
  'Ransomware & Malware growth',
  'Covert Data Exfiltration',
  'Rapidly Evolving Tactics, Techniques, and Procedures (TTPs)',
  'Root Access and Stolen Credentials',
  'Cover tracks and remain undetected',
];
export const listThree = [
  'Autonomous Operations',
  'Artificial Intelligence & Deep Learning',
  'Turnkey Deployment',
  'Protects the complete attack surface',
  'Protects against known and unknown threats',
  'Real-time threat detection',
  'Real-time response',
];
