import React, { FC } from 'react';
import { TDividerProps } from './types';
import { Block } from './styled';

const Divider: FC<TDividerProps> = ({ width, height }) => {
  return <Block styledWidth={width!} styledHeight={height!} />;
};

Divider.defaultProps = {
  width: 0,
  height: 0,
};

export default Divider;
