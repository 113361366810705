import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { KeyCaptcha } from '../../assets/enums';
import { Backdrop } from '../common/Backdrop';
import { Formik } from 'formik';
import { ContactSchema } from './ContactSchema';
import { InputField } from '../common/Input/InputField';
import { TFormModalProps, TModalProps } from './types';
import { ReactComponent as CloseButton } from '../../assets/icons/close-modal.svg';
import {
  InputBoxWraper,
  ModalButton,
  ModalContent,
  TitleForm,
  FormModal,
  CheckBoxWraper,
} from './styled';
import { Button } from '../common/Button';
import { CheckboxField } from '../common/Checkbox/CheckboxField';
import { LinkTo } from '../Styled';
import { Divider } from '../common/Divider';
import { sendEarlyAccessEmail } from '../../helpers/sendEmail';

const initialValues: TFormModalProps = {
  name: '',
  lastName: '',
  email: '',
  agreeContact: false,
  agreeMail: false,
  agreePersonalData: false,
};

export const Modal: React.FC<TModalProps> = ({
  active,
  handleClick,
  setActive,
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>();
  const onSubmitData = async (
    values: TFormModalProps,
    { resetForm }: { resetForm: any },
  ) => {
    if (recaptchaRef?.current?.getValue() === '') {
      toast.error('Please verify that you are a Human.');
      return;
    }

    sendEarlyAccessEmail(values)
      .then(_res => {
        toast.success(`Thank you ${values.name}! Your submition has been sent`);
        resetForm({ values: initialValues });
        setActive(!active);
        window.location.replace('/#/success');
      })
      .catch(_error => {
        toast.error(
          'Hmm, something went wrong. Try emailing us directly at info@cyberactive.com',
        );
      });
  };

  return (
    <Backdrop className={active ? 'active' : ''}>
      <ModalContent className={active ? 'active ' : ''}>
        <ModalButton onClick={handleClick}>
          <CloseButton />
        </ModalButton>
        <TitleForm>Join the CyberActive Launch Team</TitleForm>
        <Formik
          initialValues={initialValues}
          validationSchema={ContactSchema}
          onSubmit={onSubmitData}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <FormModal onSubmit={handleSubmit}>
              <InputBoxWraper>
                <InputField
                  type="text"
                  name="name"
                  label="FirstName"
                  onChange={handleChange}
                  value={values.name}
                  className="modalInput"
                />
                <InputField
                  type="text"
                  name="lastName"
                  label="Last name"
                  onChange={handleChange}
                  value={values.lastName}
                  className="modalInput"
                />
                <InputField
                  type="text"
                  name="email"
                  label="Work e-mail"
                  onChange={handleChange}
                  value={values.email}
                  className="modalInput"
                />
              </InputBoxWraper>
              <CheckBoxWraper>
                <CheckboxField
                  name="agreeContact"
                  label="Yes, CyberActive can contact me directly about products and services."
                  checked={values.agreeContact}
                  onChange={checked =>
                    setFieldValue('agreeContact', checked.value)
                  }
                />
                <CheckboxField
                  name="agreeMail"
                  label="Yes, add me to CyberActive mailing list for relevant news and content."
                  checked={values.agreeMail}
                  onChange={checked =>
                    setFieldValue('agreeMail', checked.value)
                  }
                />
                <CheckboxField
                  name="agreePersonalData"
                  label="I agree to allow CyberActive to store and process my personal data."
                  checked={values.agreePersonalData}
                  onChange={checked =>
                    setFieldValue('agreePersonalData', checked.value)
                  }
                />
              </CheckBoxWraper>
              <LinkTo
                to="/privacy"
                target="_blank"
                aria-label="link page"
                title="Go to Privacy Policy"
              >
                View our privacy policy
              </LinkTo>
              <Divider height={20} />
              <ReCAPTCHA
                ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                sitekey={KeyCaptcha.SITE_KEY}
                stoken={KeyCaptcha.SECURITY_KEY}
              />
              <Divider height={20} />
              <Button
                type="submit"
                name="secondary"
                title="Send"
                width="416px"
                height="46px"
              />
            </FormModal>
          )}
        </Formik>
      </ModalContent>
    </Backdrop>
  );
};
