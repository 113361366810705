import styled from 'styled-components';
import { Section } from '../../components/Styled';
import { DesktopBreakpoint } from '../../helpers';

export const PageSectionWraper = styled(Section)`
  display: flex;
  justify-content: 'flex-start';
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (min-width: ${DesktopBreakpoint.px}) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

export const ItemPurpose = styled.span`
  position: relative;
`;
export const StyledListPurposes = styled.ul`
  padding-left: 20px;
  list-style-type: disc;
  list-style-position: outside;
`;
export const ListItem = styled.li`
  margin-bottom: 14px;
`;
