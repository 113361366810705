export const pages = [
  {
    href: '/privacy',
    title: 'Privacy Policy',
  },
  {
    href: '/terms',
    title: 'Terms of Service',
  },
];
